import { IconProps } from "./utils/utils";

const StorageIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M10.0002 7.0567C7.39568 7.0567 4.94079 6.72155 3.08755 6.11334C1.09662 5.45944 0 4.54147 0 3.52858C0 2.51569 1.09662 1.59733 3.08755 0.94342C4.94079 0.335217 7.39568 6.10352e-05 10.0002 6.10352e-05C12.6048 6.10352e-05 15.0592 0.335217 16.912 0.94342C18.9043 1.59733 19.9996 2.5153 19.9996 3.52858C19.9996 4.54186 18.9043 5.45944 16.912 6.11334C15.0592 6.72155 12.6043 7.0567 10.0002 7.0567ZM10.0002 0.781311C4.62867 0.781311 0.885446 2.22897 0.885446 3.52858C0.885446 4.82819 4.62867 6.27545 10.0002 6.27545C15.3718 6.27545 19.1146 4.8278 19.1146 3.52858C19.1146 2.22936 15.3713 0.781311 10.0002 0.781311Z" />
      <path d="M10.0002 11.3712C7.39568 11.3712 4.94079 11.036 3.08755 10.4278C1.09662 9.77389 0 8.85592 0 7.84264C0 7.73904 0.0466439 7.63968 0.12967 7.56642C0.212697 7.49317 0.325305 7.45201 0.442723 7.45201C0.56014 7.45201 0.672748 7.49317 0.755775 7.56642C0.838802 7.63968 0.885445 7.73904 0.885445 7.84264C0.885445 9.14068 4.62867 10.5899 10.0002 10.5899C15.3718 10.5899 19.1146 9.14068 19.1146 7.84264C19.1146 7.73904 19.1612 7.63968 19.2442 7.56642C19.3273 7.49317 19.4399 7.45201 19.5573 7.45201C19.6747 7.45201 19.7873 7.49317 19.8703 7.56642C19.9534 7.63968 20 7.73904 20 7.84264C20 8.85592 18.9038 9.77389 16.9125 10.4278C15.0592 11.036 12.6043 11.3712 10.0002 11.3712Z" />
      <path d="M10.0002 15.6856C7.39568 15.6856 4.94079 15.3504 3.08755 14.7422C1.09662 14.0883 0 13.1704 0 12.1571C0 12.0535 0.0466439 11.9541 0.12967 11.8809C0.212697 11.8076 0.325305 11.7665 0.442723 11.7665C0.56014 11.7665 0.672748 11.8076 0.755775 11.8809C0.838802 11.9541 0.885445 12.0535 0.885445 12.1571C0.885445 13.4567 4.62867 14.9044 10.0002 14.9044C15.3718 14.9044 19.1146 13.4567 19.1146 12.1571C19.1146 12.0535 19.1612 11.9541 19.2442 11.8809C19.3273 11.8076 19.4399 11.7665 19.5573 11.7665C19.6747 11.7665 19.7873 11.8076 19.8703 11.8809C19.9534 11.9541 20 12.0535 20 12.1571C20 13.1704 18.9038 14.0883 16.9125 14.7422C15.0592 15.3516 12.6043 15.6856 10.0002 15.6856Z" />
      <path d="M10.0002 20.0001C7.39568 20.0001 4.94079 19.6649 3.08755 19.0563C1.09662 18.4028 0 17.4848 0 16.4715V3.52858C0 3.42498 0.0466439 3.32562 0.12967 3.25237C0.212697 3.17911 0.325305 3.13795 0.442723 3.13795C0.56014 3.13795 0.672748 3.17911 0.755775 3.25237C0.838802 3.32562 0.885445 3.42498 0.885445 3.52858V16.4715C0.885445 17.7712 4.62867 19.2188 10.0002 19.2188C15.3718 19.2188 19.1146 17.7712 19.1146 16.4715V3.52858C19.1146 3.42498 19.1612 3.32562 19.2442 3.25237C19.3273 3.17911 19.4399 3.13795 19.5573 3.13795C19.6747 3.13795 19.7873 3.17911 19.8703 3.25237C19.9534 3.32562 20 3.42498 20 3.52858V16.4715C20 17.4848 18.9038 18.4028 16.9125 19.0563C15.0592 19.6649 12.6043 20.0001 10.0002 20.0001Z" />
      <path d="M17.3348 8.23365C17.3058 8.23359 17.2769 8.23097 17.2485 8.22584C17.2201 8.2208 17.1924 8.21334 17.1657 8.20357C17.1389 8.19387 17.1132 8.18183 17.0891 8.16764C17.0405 8.13936 16.999 8.10268 16.9669 8.05982C16.951 8.03845 16.9374 8.01583 16.9262 7.99225C16.9151 7.96868 16.9067 7.94421 16.9009 7.9192C16.8891 7.86882 16.8891 7.81684 16.9009 7.76647C16.9121 7.71611 16.9344 7.66822 16.9667 7.62556C16.999 7.58291 17.0406 7.54636 17.0891 7.51803C17.1132 7.50384 17.1389 7.4918 17.1657 7.48209C17.1924 7.47232 17.2201 7.46487 17.2485 7.45982C17.3056 7.44967 17.3645 7.44967 17.4216 7.45982C17.5072 7.47512 17.5858 7.51219 17.6478 7.56647C17.6683 7.58486 17.6868 7.60486 17.7032 7.62623C17.7353 7.66872 17.7577 7.71636 17.7691 7.76647C17.7803 7.8169 17.7803 7.86877 17.7691 7.9192C17.7634 7.94421 17.755 7.96868 17.7439 7.99225C17.7327 8.01583 17.7191 8.03845 17.7032 8.05982C17.6869 8.08114 17.6684 8.10103 17.6478 8.1192C17.6273 8.13734 17.6048 8.15357 17.5805 8.16764C17.5565 8.18175 17.531 8.19378 17.5044 8.20357C17.4776 8.21329 17.4499 8.22074 17.4216 8.22584C17.393 8.23096 17.364 8.23358 17.3348 8.23365Z" />
      <path d="M15.5569 8.62584C15.5279 8.62578 15.499 8.62316 15.4705 8.61803C15.4422 8.61299 15.4145 8.60553 15.3878 8.59576C15.3609 8.58605 15.3353 8.57402 15.3112 8.55982C15.2869 8.54576 15.2644 8.52952 15.2439 8.51139C15.2026 8.47515 15.1699 8.43198 15.1478 8.38443C15.1367 8.36086 15.1283 8.3364 15.1226 8.31139C15.1167 8.28567 15.1139 8.25949 15.1141 8.23326C15.114 8.20704 15.1168 8.18086 15.1226 8.15514C15.1284 8.13015 15.1368 8.1057 15.1478 8.08209C15.1701 8.03475 15.2027 7.99174 15.2439 7.95553C15.2643 7.93727 15.2869 7.92091 15.3112 7.9067C15.3353 7.89251 15.3609 7.88047 15.3878 7.87076C15.4145 7.861 15.4422 7.85354 15.4705 7.8485C15.5275 7.83834 15.5862 7.83834 15.6432 7.8485C15.6715 7.85354 15.6993 7.861 15.726 7.87076C15.7528 7.88047 15.7785 7.89251 15.8026 7.9067C15.851 7.93527 15.8926 7.97205 15.9248 8.0149C15.9408 8.03609 15.9544 8.05859 15.9655 8.08209C15.9767 8.10564 15.9851 8.13011 15.9907 8.15514C15.9967 8.18085 15.9996 8.20702 15.9996 8.23326C15.9997 8.2595 15.9967 8.28568 15.9907 8.31139C15.9852 8.33644 15.9768 8.36093 15.9655 8.38443C15.9544 8.40806 15.9408 8.43069 15.9248 8.45201C15.8927 8.49487 15.8512 8.53155 15.8026 8.55982C15.7785 8.57402 15.7528 8.58605 15.726 8.59576C15.6993 8.60553 15.6715 8.61299 15.6432 8.61803C15.6148 8.62316 15.5859 8.62578 15.5569 8.62584Z" />
      <path d="M13.7789 9.01803C13.7491 9.01805 13.7195 9.01544 13.6903 9.01022C13.662 9.00512 13.6343 8.99766 13.6075 8.98795C13.5264 8.9588 13.4571 8.9087 13.4087 8.8442C13.3927 8.82292 13.379 8.80028 13.368 8.77662C13.3569 8.75305 13.3485 8.72859 13.3428 8.70357C13.3371 8.67849 13.3343 8.65298 13.3344 8.6274C13.3342 8.60118 13.337 8.575 13.3428 8.54928C13.3494 8.52436 13.3585 8.50004 13.3702 8.47662C13.3925 8.42938 13.425 8.3864 13.4658 8.35006C13.4867 8.332 13.5094 8.31565 13.5336 8.30123C13.5576 8.28712 13.5831 8.27509 13.6097 8.26529C13.6365 8.25558 13.6642 8.24813 13.6925 8.24303C13.7496 8.23287 13.8085 8.23287 13.8656 8.24303C13.894 8.24807 13.9217 8.25553 13.9484 8.26529C13.9751 8.27517 14.0008 8.2872 14.025 8.30123C14.0491 8.31566 14.0716 8.332 14.0923 8.35006C14.1129 8.36814 14.1313 8.38804 14.1472 8.40943C14.1632 8.43062 14.1768 8.45312 14.1879 8.47662C14.1989 8.50023 14.2073 8.52468 14.2132 8.54967C14.2191 8.57538 14.222 8.60156 14.222 8.62779C14.2219 8.65338 14.219 8.6789 14.2132 8.70397C14.2074 8.72898 14.199 8.75344 14.1879 8.77701C14.1769 8.80067 14.1633 8.82331 14.1472 8.84459C14.1313 8.86599 14.1129 8.88589 14.0923 8.90397C14.0717 8.92202 14.0492 8.93824 14.025 8.9524C14.0008 8.96644 13.9751 8.97847 13.9484 8.98834C13.9217 8.99811 13.894 9.00556 13.8656 9.01061C13.8371 9.01561 13.808 9.0181 13.7789 9.01803Z" />
      <path d="M17.3348 12.5477C17.3058 12.5478 17.2769 12.5453 17.2485 12.5403C17.2201 12.5353 17.1924 12.5278 17.1657 12.518C17.1389 12.5083 17.1132 12.4963 17.0891 12.4821C17.0648 12.4679 17.0423 12.4515 17.0218 12.4333C16.939 12.3599 16.8924 12.2606 16.8921 12.1571C16.892 12.1309 16.895 12.1047 16.9009 12.079C16.9067 12.054 16.9151 12.0295 16.9262 12.0059C16.9374 11.9823 16.951 11.9597 16.9669 11.9383C16.983 11.917 17.0013 11.8971 17.0218 11.879C17.0423 11.8608 17.0649 11.8446 17.0891 11.8305C17.1132 11.8163 17.1389 11.8043 17.1657 11.7946C17.1924 11.7848 17.2201 11.7774 17.2485 11.7723C17.3056 11.7622 17.3645 11.7622 17.4216 11.7723C17.4784 11.7824 17.5324 11.8021 17.5805 11.8305C17.6048 11.8446 17.6273 11.8608 17.6478 11.879C17.6683 11.8972 17.6868 11.9171 17.7032 11.9383C17.7191 11.9597 17.7327 11.9823 17.7439 12.0059C17.755 12.0295 17.7634 12.054 17.7691 12.079C17.7749 12.1047 17.7777 12.1309 17.7775 12.1571C17.7772 12.2606 17.7306 12.3599 17.6478 12.4333C17.6273 12.4515 17.6048 12.4679 17.5805 12.4821C17.5324 12.5105 17.4784 12.5303 17.4216 12.5403C17.393 12.5453 17.3639 12.5478 17.3348 12.5477Z" />
      <path d="M15.5569 12.9399C15.5279 12.94 15.499 12.9375 15.4705 12.9325C15.4422 12.9274 15.4145 12.92 15.3877 12.9102C15.3609 12.9005 15.3353 12.8885 15.3112 12.8743C15.2869 12.8602 15.2643 12.844 15.2439 12.8258C15.2234 12.8076 15.2049 12.7877 15.1885 12.7665C15.1726 12.7451 15.159 12.7225 15.1478 12.6989C15.1367 12.6753 15.1283 12.6508 15.1225 12.6258C15.1113 12.5754 15.1113 12.5235 15.1225 12.4731C15.1283 12.4481 15.1367 12.4236 15.1478 12.4001C15.159 12.3765 15.1726 12.3538 15.1885 12.3325C15.2049 12.3112 15.2234 12.2914 15.2439 12.2731C15.2643 12.255 15.2869 12.2387 15.3112 12.2247C15.3353 12.2105 15.3609 12.1984 15.3877 12.1887C15.4145 12.179 15.4422 12.1715 15.4705 12.1665C15.5275 12.1563 15.5862 12.1563 15.6432 12.1665C15.6715 12.1715 15.6993 12.179 15.726 12.1887C15.7528 12.1984 15.7785 12.2105 15.8026 12.2247C15.8268 12.2387 15.8494 12.255 15.8699 12.2731C15.8903 12.2913 15.9087 12.3111 15.9248 12.3325C15.9407 12.3538 15.9543 12.3765 15.9655 12.4001C15.9768 12.4236 15.9852 12.448 15.9907 12.4731C16.0025 12.5235 16.0025 12.5755 15.9907 12.6258C15.9852 12.6509 15.9768 12.6754 15.9655 12.6989C15.9543 12.7225 15.9407 12.7451 15.9248 12.7665C15.9087 12.7878 15.8903 12.8077 15.8699 12.8258C15.8494 12.844 15.8268 12.8602 15.8026 12.8743C15.7785 12.8885 15.7528 12.9005 15.726 12.9102C15.6993 12.92 15.6715 12.9274 15.6432 12.9325C15.6148 12.9375 15.5858 12.94 15.5569 12.9399Z" />
      <path d="M13.7788 13.3321C13.7491 13.3323 13.7195 13.3298 13.6903 13.3247C13.662 13.3196 13.6342 13.3121 13.6075 13.3024C13.5539 13.2826 13.5051 13.254 13.4636 13.218C13.4226 13.1817 13.3901 13.1385 13.368 13.0911C13.3569 13.0675 13.3485 13.043 13.3428 13.018C13.3371 12.9929 13.3343 12.9674 13.3344 12.9419C13.3346 12.8383 13.381 12.7389 13.4636 12.6653C13.4845 12.6474 13.5072 12.6311 13.5314 12.6169C13.5554 12.6027 13.5809 12.5907 13.6075 12.5809C13.6342 12.5712 13.662 12.5638 13.6903 12.5587C13.7762 12.5433 13.8654 12.5509 13.9464 12.5804C14.0274 12.61 14.0965 12.6602 14.145 12.7247C14.1609 12.746 14.1745 12.7687 14.1857 12.7922C14.1968 12.8158 14.2052 12.8403 14.2109 12.8653C14.2227 12.9157 14.2227 12.9677 14.2109 13.018C14.2052 13.043 14.1968 13.0675 14.1857 13.0911C14.1745 13.1147 14.1609 13.1373 14.145 13.1587C14.1129 13.2015 14.0714 13.2382 14.0228 13.2665C13.9986 13.2805 13.9729 13.2925 13.9462 13.3024C13.9195 13.3122 13.8918 13.3196 13.8634 13.3247C13.8356 13.3296 13.8072 13.332 13.7788 13.3321Z" />
      <path d="M17.3348 16.8622C17.3058 16.8622 17.2769 16.8597 17.2485 16.8547C17.2201 16.8497 17.1924 16.8422 17.1657 16.8325C17.1389 16.8229 17.1132 16.811 17.0891 16.7969C17.0649 16.7826 17.0424 16.7663 17.0218 16.7481C17.0013 16.73 16.9829 16.7101 16.9669 16.6887C16.9509 16.6675 16.9373 16.645 16.9262 16.6215C16.9152 16.5979 16.9067 16.5735 16.9009 16.5485C16.895 16.5228 16.8921 16.4966 16.8921 16.4704C16.8922 16.4448 16.8951 16.4193 16.9009 16.3942C16.9067 16.3692 16.9151 16.3447 16.9262 16.3212C16.9372 16.2975 16.9508 16.2748 16.9669 16.2536C16.999 16.2107 17.0405 16.174 17.0891 16.1458C17.1132 16.1316 17.1389 16.1195 17.1657 16.1098C17.1924 16.1001 17.2201 16.0926 17.2485 16.0876C17.3056 16.0774 17.3645 16.0774 17.4216 16.0876C17.4499 16.0927 17.4776 16.1001 17.5044 16.1098C17.531 16.1196 17.5565 16.1317 17.5805 16.1458C17.6048 16.1598 17.6273 16.1761 17.6478 16.1942C17.6684 16.2124 17.6869 16.2323 17.7032 16.2536C17.7192 16.2748 17.7329 16.2975 17.7439 16.3212C17.755 16.3447 17.7634 16.3692 17.7691 16.3942C17.7748 16.4193 17.7776 16.4448 17.7775 16.4704C17.7777 16.4966 17.7749 16.5228 17.7691 16.5485C17.7633 16.5735 17.7549 16.5979 17.7439 16.6215C17.7162 16.681 17.6723 16.7335 17.616 16.7744C17.5598 16.8153 17.493 16.8433 17.4216 16.8559C17.393 16.8605 17.3639 16.8626 17.3348 16.8622Z" />
      <path d="M15.5569 17.2544C15.5279 17.2544 15.499 17.2519 15.4705 17.2469C15.4422 17.2419 15.4145 17.2344 15.3877 17.2247C15.3609 17.215 15.3353 17.2029 15.3111 17.1887C15.2627 17.16 15.221 17.1233 15.1885 17.0805C15.1728 17.0592 15.1593 17.0368 15.1482 17.0133C15.137 16.9898 15.1285 16.9654 15.123 16.9403C15.1171 16.9146 15.1141 16.8884 15.1141 16.8622C15.1142 16.8366 15.1172 16.8111 15.123 16.786C15.1285 16.7609 15.137 16.7365 15.1482 16.713C15.1593 16.6894 15.1727 16.6667 15.1885 16.6454C15.2047 16.6241 15.2233 16.6042 15.2439 16.586C15.2643 16.5679 15.2869 16.5516 15.3111 16.5376C15.3353 16.5234 15.3609 16.5113 15.3877 16.5016C15.4145 16.4919 15.4422 16.4844 15.4705 16.4794C15.5275 16.4692 15.5862 16.4692 15.6432 16.4794C15.6715 16.4844 15.6993 16.4919 15.726 16.5016C15.7528 16.5113 15.7785 16.5234 15.8026 16.5376C15.8268 16.5516 15.8494 16.5679 15.8699 16.586C15.8903 16.6042 15.9087 16.624 15.9248 16.6454C15.9408 16.6666 15.9545 16.6893 15.9655 16.713C15.9768 16.7365 15.9852 16.7609 15.9907 16.786C15.9965 16.8111 15.9995 16.8366 15.9996 16.8622C15.9996 16.8884 15.9966 16.9146 15.9907 16.9403C15.9852 16.9654 15.9768 16.9898 15.9655 17.0133C15.9544 17.0368 15.9408 17.0593 15.9248 17.0805C15.9088 17.1019 15.8904 17.1218 15.8699 17.1399C15.8493 17.1581 15.8267 17.1744 15.8026 17.1887C15.7785 17.2029 15.7528 17.215 15.726 17.2247C15.6993 17.2344 15.6715 17.2419 15.6432 17.2469C15.6148 17.2519 15.5858 17.2544 15.5569 17.2544Z" />
      <path d="M13.7788 17.6469C13.7491 17.647 13.7194 17.6443 13.6903 17.6391C13.662 17.634 13.6342 17.6266 13.6075 17.6169C13.5266 17.5873 13.4575 17.5372 13.4089 17.4729C13.3603 17.4087 13.3343 17.3332 13.3344 17.2559C13.3343 17.2303 13.3371 17.2048 13.3428 17.1797C13.3485 17.1547 13.3569 17.1303 13.368 17.1067C13.3901 17.0593 13.4226 17.0161 13.4636 16.9797C13.5051 16.9438 13.5539 16.9152 13.6075 16.8954C13.6342 16.8857 13.662 16.8782 13.6903 16.8731C13.7474 16.863 13.8063 16.863 13.8634 16.8731C13.8918 16.8782 13.9195 16.8856 13.9462 16.8954C13.9729 16.9052 13.9986 16.9173 14.0228 16.9313C14.0714 16.9596 14.1129 16.9963 14.145 17.0391C14.1609 17.0605 14.1745 17.0831 14.1857 17.1067C14.1968 17.1303 14.2052 17.1547 14.2109 17.1797C14.2167 17.2048 14.2197 17.2303 14.2198 17.2559C14.2198 17.3202 14.2018 17.3835 14.1675 17.4402C14.1331 17.4969 14.0834 17.5452 14.0228 17.5809C13.9986 17.595 13.9729 17.607 13.9462 17.6169C13.9195 17.6266 13.8918 17.6341 13.8634 17.6391C13.8356 17.6442 13.8073 17.6468 13.7788 17.6469Z" />
    </svg>
  );
};

export default StorageIcon;
