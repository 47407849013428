import { PodReasons } from "../../autoscalers/Utils";
import BlockedNodesFilter, { BlockedNodesFilterProps } from "./BlockedNodesFilter";
import React from "react";

const FILTERS: BlockedNodesFilterProps[] = [
  {
    label: "Unevictable workloads",
    paramsKeys: [
      PodReasons.NotSafeToEvictAnnotation,
      PodReasons.NotSafeToEvictLabel,
      PodReasons.PdbPreventingScaleDown,
    ],
  },
  {
    label: "Pods without owner",
    paramsKeys: [
      PodReasons.PodWithNoOwner,
      PodReasons.PodOwnerReachedToMinimumReplicas,
      PodReasons.DeprecatedPodWithNoOwner,
    ],
  },
  {
    label: "Un-ready workloads",
    paramsKeys: [PodReasons.PdbTemporaryBlockingScaleDown],
  },
];

const KubeSystemFilter: BlockedNodesFilterProps[] = [
  {
    label: "Kube-system workloads",
    paramsKeys: [PodReasons.UnmovableKubeSystemPod],
  },
  {
    label: "Kube-system workloads",
    paramsKeys: [PodReasons.PodPlacementKubeSystemPod],
  },
  {
    label: "Workloads with local storage",
    paramsKeys: [PodReasons.LocalStorageRequested],
  },
  {
    label: "Workloads with local storage",
    paramsKeys: [PodReasons.PodPlacementPodWithLocalStorage],
  },
];

const ALL_FILTERS = [...FILTERS, ...KubeSystemFilter];

export const allFiltersQueryParams = [...new Set(ALL_FILTERS.flatMap((filter) => filter.paramsKeys))];

interface Props {
  foundClusterAutoscaler?: boolean;
}

const BlockedNodesFiltersWrapper = ({ foundClusterAutoscaler = true }: Props) => {
  if (foundClusterAutoscaler) {
    return (
      <div className="w-full flex gap-2 item flex-grow h-[58px]">
        {ALL_FILTERS.map((filter) => (
          <BlockedNodesFilter key={filter.label} {...filter} />
        ))}
      </div>
    );
  }
  return (
    <div className="w-full flex gap-2 item flex-grow h-[58px]">
      {FILTERS.map((filter) => (
        <BlockedNodesFilter key={filter.label} {...filter} />
      ))}
    </div>
  );
};

export default BlockedNodesFiltersWrapper;
