import { CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { useEffect, useState } from "react";
import PlainRolloutIcon from "../../Icons/PlainRolloutIcon";
import { useMainContext } from "../../MainContext";
import { SCALEOPS_COLORS } from "../../colors";
import { CurrencyFormatter } from "../../utils/formatterUtils";
import { getDataGridSx, PAGE_CONTENT_CLASS_NAME } from "../../utils/styleUtils";
import { KeyValTooltipOption } from "../../utils/tooltipUtils";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import Button, { BUTTON_VARIANTS } from "../Button";
import ProgressBar from "../ProgressBar";
import SwitchBox, { THEMES } from "../SwitchBox";
import { default as Tooltip } from "../Tooltip";
import YouHaveReadOnlyAccess from "../YouHaveReadOnlyAccess";
import { ActionCell } from "./Action";
import AutomateUnevictableTooltip from "./AutomateUnevictableTooltip";
import UnevictableResourcesCharts from "./UnevictableResourcesCharts";
import { UevictableTableRowType } from "./Utils";
import { useAutomatePods } from "./useAutomatePods";

export const OPTIMIZE_UNEVICTABLE_PODS_TITLE_ID = "optimizeUnevictablePods";

interface Props {
  rows?: UevictableTableRowType[];
  isLoading: boolean;
}

enum Columns {
  Action = "Action",
  SavingsAvailable = "Savings Available",
  Progress = "Automated %",
  Automate = "Automate",
}

const DEFAULT_COL_PROPS: Partial<GridColDef> = {
  flex: 1,
  minWidth: 30,
  type: "string",
  align: "center",
  headerAlign: "center",
  disableColumnMenu: true,
  sortable: true,
  cellClassName: "h-[1000px]",
};

const getColumns = (isReadOnly: boolean): GridColDef[] => {
  const { currentCluster } = useMainContext();
  return [
    {
      field: "action",
      headerName: Columns.Action,
      headerAlign: "center",
      flex: 2.1,
      minWidth: 400,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        const action = params.row.action;
        let link = action.exploreWorkloadsLink;
        if (action.exploreWorkloadsLink !== undefined && currentCluster != undefined) {
          link = `${action.exploreWorkloadsLink}&currentClusterURLParam=${currentCluster}`;
        }
        return (
          <Tooltip
            className="w-full"
            title={
              <>
                <b>{action.title}:</b> {action.description}
              </>
            }
            maxWidth={500}
          >
            <ActionCell
              title={action.title}
              icon={action.icon}
              description={action.description}
              exploreWorkloadsLink={link}
            />
          </Tooltip>
        );
      },
    },
    {
      ...DEFAULT_COL_PROPS,
      field: "savingAvailable",
      headerName: Columns.SavingsAvailable,
      renderHeader: () => (
        <Typography
          variant="body2"
          fontWeight={700}
          sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}
          className={"flex flex-col items-center gap-[2px]"}
        >
          <span className={"text-center"}>Savings Available</span>
          <span className="text-text-darkGray text-[.625rem] text-center">(monthly)</span>
        </Typography>
      ),
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        const displayValue = CurrencyFormatter().format(params.row.savingAvailable ?? 0);

        return <div className="text-guideline-darkGreen">{displayValue}</div>;
      },
    },
    {
      ...DEFAULT_COL_PROPS,
      field: "optimizedPods",
      headerName: Columns.Progress,
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        const optimizedPods = Number(params.row.optimizedPods);
        const totalPods = Number(params.row.totalPods);

        let percentageOfoptimizedPods = Math.round((optimizedPods / totalPods) * 100);
        switch (true) {
          case percentageOfoptimizedPods > 100:
            percentageOfoptimizedPods = 100;
            break;
          case percentageOfoptimizedPods < 0 || isNaN(percentageOfoptimizedPods):
            percentageOfoptimizedPods = 0;
            break;
        }

        const tooltipContent = (
          <div>
            <KeyValTooltipOption name="Optimized" value={optimizedPods} background={SCALEOPS_COLORS.main.green} />
            <KeyValTooltipOption
              name="Unoptimized"
              value={totalPods - optimizedPods}
              background={SCALEOPS_COLORS.main.info}
            />
          </div>
        );
        return (
          <Tooltip title={tooltipContent} className="w-full" maxWidth={500}>
            <ProgressBar
              percentage={percentageOfoptimizedPods}
              mainFill={SCALEOPS_COLORS.main.green}
              displayValue={{ value1: optimizedPods, value2: totalPods }}
            />
          </Tooltip>
        );
      },
    },
    {
      ...DEFAULT_COL_PROPS,
      field: "isAutomated",
      headerName: Columns.Automate,
      renderCell: (params: GridRenderCellParams<string, UevictableTableRowType, string>) => {
        const [isHoveredOnRolloutButton, setIsHoveredOnRolloutButton] = useState(false);
        const setAutomate = useAutomatePods({ toastSuffix: params.row.action.title.toLocaleLowerCase() });
        const [isAutomated, setIsAutomated] = useState(!!params.value);

        useEffect(() => {
          setIsAutomated(!!params.value);
        }, [params.value]);

        const isRolloutButtonDisabled = isReadOnly || !isAutomated;

        return (
            <div>
              <Tooltip
                title={isReadOnly ? <YouHaveReadOnlyAccess /> : <AutomateUnevictableTooltip />}
                className="flex items-center gap-2 cursor-pointer"
                maxWidth={800}
                disabled={isHoveredOnRolloutButton && isRolloutButtonDisabled}
              >
                <div className="relative flex flex-col gap-2 items-end">
                  <SwitchBox
                    disabled={isReadOnly}
                    title="AUTOMATE"
                    handleClick={() => {
                      setAutomate.mutate({
                        [params.row.id]: !params.row.isAutomated,
                      });
                      setIsAutomated(!isAutomated);
                    }}
                    checked={isAutomated}
                    theme={THEMES.Purple}
                  />
                </div>
              </Tooltip>
            <div className="gap-4 mt-2">
              {!!params.row?.rolloutAction && (
                <Tooltip
                  title={<>Rollout is only available for workloads that have been automated.</>}
                  maxWidth={800}
                  disabled={!isRolloutButtonDisabled}
                  className="w-full"
                >
                  <div
                    onMouseEnter={() => setIsHoveredOnRolloutButton(true)}
                    onMouseLeave={() => setIsHoveredOnRolloutButton(false)}
                    className="w-full"
                  >
                    <Button
                      onClick={() => {
                        params.row?.rolloutAction && params.row.rolloutAction();
                      }}
                      className="w-full"
                      variant={BUTTON_VARIANTS.extraSmall}
                      label={
                        <div className="flex items-center gap-0.5">
                          <PlainRolloutIcon width={14} height={14} />
                          <span>Rollout workloads</span>
                        </div>
                      }
                      disabled={isRolloutButtonDisabled}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
  ];
};

const UevictableTable = ({ rows, isLoading }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  if (isLoading && !rows) {
    return (
      <div className="w-full h-[60vh] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={clsx("flex flex-col gap-4", PAGE_CONTENT_CLASS_NAME)}>
      <Typography variant="body1" fontWeight={700} id={OPTIMIZE_UNEVICTABLE_PODS_TITLE_ID}>
        Automate unevictable workloads
      </Typography>
      <UnevictableResourcesCharts />
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        rows={rows || []}
        columns={getColumns(isReadyOnlyFrontEnd)}
        autoHeight={true}
        rowHeight={120}
        loading={isLoading}
        disableSelectionOnClick
        hideFooter
      />
    </div>
  );
};

export default UevictableTable;
