import { useEffect, useState } from "react";
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import { GetNodeGroupInfoInput, ScaleDownReasons } from "../../api/fetcher";
import { NodePoolFilterQueryParams } from "../autoscalers/NodeGroups";

export type FilterParamObject = {
  [key: string]: string[] | boolean | string | undefined | number;
};

// Marriage with BE structs:
export enum FilterOptions {
  Namespace = "namespace",
  UpdateMode = "updateMode",
  WorkloadErrors = "workloadErrors",
  IsWorkloadErrorsExclude = "isWorkloadErrorsExclude",
  Tags = "tags",
  IsTagExclude = "isTagsExclude",
  Clusters = "clusters",
  Policy = "policy",
  Type = "type",
  Annotation = "annotation",
  Label = "label",
  PriorityClass = "priorityClass",
  OptimizationGaps = "optimizationGaps",
  HpaOptimizationType = "hpaOptimizationType",
  OverProvisioned = "overProvisioned",
  UnderProvisioned = "underProvisioned",
  OverProvisioned2 = "overProvisionedV2",
  UnderProvisioned2 = "underProvisionedV2",
  overProvisionedWRTOrigin = "overProvisionedWRTOrigin",
  underProvisionedWRTOrigin = "underProvisionedWRTOrigin",
  Automated = "automated",
  UnAutomated = "unAutomated",
  HasMinReplicasSavings = "hasMinReplicasSavings",
  HasHpaAutomatedPolicy = "hasHpaAutomatedPolicy",
  HasHpaUnAutomatedPolicy = "hasHpaUnAutomatedPolicy",
  MisconfiguredHpa = "misconfiguredHpa",
  HasHpa = "hasHpa",
  HasGPU = "hasGPU",
  SavingsWithoutMinDiff = "savingsWithoutMinDiff",
  UnEvictable = "unEvictable",
  UnevictableTypes = "unevictableTypes",
  Savings = "savings",
  OOM = "oom",
  PendingConsumers = "pendingConsumers",
  wastedResources = "wastedResources",
  isWastedResourcesExclude = "isWastedResourcesExclude",
  logicalWastedResources = "logicalWastedResources",
  IsNamespceExclude = "isNamespaceExclude",
  IsClustersExclude = "isClustersExclude",
  IsUpdateModeExclude = "isUpdateModeExclude",
  IsPolicyExclude = "isPolicyExclude",
  IsTypeExclude = "isTypeExclude",
  IsAnnotationExclude = "isAnnotationExclude",
  IsLabelExclude = "isLabelExclude",
  IsPriorityClassExclude = "isPriorityClassExclude",
  IsOptimizationGapsExclude = "isOptimizationGapsExclude",
  IsHpaPredictableExclude = "isHpaPredictableExclude",
  OverProvisionedCpu = "overProvisionedCpu",
  OverProvisionedMemory = "overProvisionedMemory",
  UnderProvisionedCpu = "underProvisionedCpu",
  UnderProvisionedMemory = "underProvisionedMemory",
  labelsLogicalOperator = "logicalLabel",
  annotationLogicalOperator = "logicalAnnotation",
  workloadErrorsLogicalOperator = "logicalWorkloadErrors",
  hpaOptimizationTypeLogicalOperator = "logicalHpaOptimizationType",
  NotUsingSmartPolicy = "notUsingSmartPolicy",
  SmartPolicy = "smartPolicy",

  NamespaceAnnotation = "namespaceAnnotation",
  IsNamespaceAnnotationExclude = "isNamespaceAnnotationExclude",
  NamespaceAnnotationLogicalOperator = "logicalNamespaceAnnotation",
  SchedulerBlockersLogicalOperator = "logicalSchedulerBlockers",
  IsSpotFriendly = "spotFriendly",
  IsSpotUnfriendly = "spotUnfriendly",
  CappingStatuses = "cappingStatus",
  SchedulerBlockers = "schedulerBlockers",
  isSchedulerBlockersExclude = "isSchedulerBlockersExclude",
}
const useFilterQueryParams = (): FilterParamObject => {
  const [filterQueryParams, setFilterQueryParams] = useState<FilterParamObject>({});

  const [tagsParams] = useQueryParam("tags", ArrayParam);
  const [isTagsExclude] = useQueryParam("isTagsExclude", BooleanParam);
  const [namespaceParams] = useQueryParam("namespaces", ArrayParam);
  const [labelLogicalOperator] = useQueryParam("logicalLabel", StringParam);
  const [clustersParams] = useQueryParam("clusters", ArrayParam);
  const [labelParams] = useQueryParam("labels", ArrayParam);
  const [annotationParams] = useQueryParam("annotations", ArrayParam);
  const [annotationLogicalOperator] = useQueryParam("logicalAnnotation", StringParam);
  const [typeParams] = useQueryParam("types", ArrayParam);
  const [priorityClassParams] = useQueryParam("priorityClassNames", ArrayParam);
  const [optimizationGapsParams] = useQueryParam("optimizationGaps", ArrayParam);
  const [hpaOptimiztionTypeParams] = useQueryParam("hpaOptimizationType", ArrayParam);
  const [hpaOptimiztionTypeLogicalOperator] = useQueryParam("logicalHpaOptimizationType", StringParam);
  const [policiesParams] = useQueryParam("policy", ArrayParam);

  const [overProvisionedParam] = useQueryParam("overProvisioned", BooleanParam);
  const [underProvisionedParam] = useQueryParam("underProvisioned", BooleanParam);

  const [cpuOverProvisionedParam] = useQueryParam("overProvisionedCpu", BooleanParam);
  const [cpuUnderProvisionedParam] = useQueryParam("underProvisionedCpu", BooleanParam);
  const [memoryOverProvisionedParam] = useQueryParam("overProvisionedMemory", BooleanParam);
  const [memoryUnderProvisionedParam] = useQueryParam("underProvisionedMemory", BooleanParam);

  const [overProvisionedParam2] = useQueryParam("overProvisionedV2", StringParam);
  const [underProvisionedParam2] = useQueryParam("underProvisionedV2", StringParam);

  const [overProvisionedWRTOrigin] = useQueryParam("overProvisionedWRTOrigin", StringParam);
  const [underProvisionedWRTOrigin] = useQueryParam("underProvisionedWRTOrigin", StringParam);

  const [workloadErrorsParam] = useQueryParam("workloadErrors", ArrayParam);
  const [isWorkloadErrorsExcludeParam] = useQueryParam("isWorkloadErrorsExclude", BooleanParam);
  const [workloadErrorsLogicalOperator] = useQueryParam("logicalWorkloadErrors", StringParam);

  const [automatedParam] = useQueryParam("isAutomated", NumberParam);
  const [unAutomatedParam] = useQueryParam("isUnAutomated", NumberParam);
  const [hasMinReplicasSavingsParam] = useQueryParam("hasMinReplicasSavings", NumberParam);
  const [hasHpaAutomatedPolicyParam] = useQueryParam("hasHpaAutomatedPolicy", NumberParam);
  const [hasHpaUnAutomatedPolicyParam] = useQueryParam("hasHpaUnAutomatedPolicy", NumberParam);
  const [misconfiguredHpaParam] = useQueryParam("misconfiguredHpa", NumberParam);
  const [hasHpaParam] = useQueryParam("hasHpa", NumberParam);
  const [hasGPUParam] = useQueryParam("hasGPU", NumberParam);
  const [savingsWithoutMinDiff] = useQueryParam("savingsWithoutMinDiff", NumberParam);
  const [unEvictableParam] = useQueryParam("isPdb", NumberParam);
  const [unevictableTypes] = useQueryParam("unevictableTypes", ArrayParam);
  const [savingsParam] = useQueryParam("availableSavingsFilter", StringParam);
  const [oomParam] = useQueryParam("outOfMemory", NumberParam);
  const [pendingConsumerParam] = useQueryParam("pendingConsumers", NumberParam);
  const [wastedResourcesParam] = useQueryParam("wastedResources", ArrayParam);
  const [isWastedResourcesExcludeParam] = useQueryParam("isWastedResourcesExclude", BooleanParam);
  const [logicalWastedResources] = useQueryParam("logicalWastedResources", StringParam);

  const [policiesParamsExclude] = useQueryParam("isPolicyExclude", NumberParam);
  const [typesParamsExclude] = useQueryParam("isTypesExclude", NumberParam);
  const [annotationParamsExclude] = useQueryParam("isAnnotationsExclude", NumberParam);
  const [labelsParamsExclude] = useQueryParam("isLabelsExclude", NumberParam);
  const [priorityClassParamsExclude] = useQueryParam("isPriorityClassNamesExclude", NumberParam);
  const [optimizationGapsParamsExclude] = useQueryParam("isOptimizationGapsExclude", NumberParam);
  const [hpaPredictableParamsExclude] = useQueryParam("isHpaPredictableExclude", NumberParam);
  const [namespacesParamsExclude] = useQueryParam("isNamespacesExclude", NumberParam);
  const [clustersParamsExclude] = useQueryParam("isClustersExclude", NumberParam);

  const [namespaceAnnotations] = useQueryParam("namespaceAnnotation", ArrayParam);
  const [logicalNamespaceAnnotations] = useQueryParam("logicalNamespaceAnnotation", StringParam);
  const [logicalSchedulerBlockers] = useQueryParam("logicalSchedulerBlockers", StringParam);
  const [namespaceAnnotationsExclude] = useQueryParam("isNamespaceAnnotationExclude", NumberParam);

  const [isUpdateModeParams] = useQueryParam("isUpdateModeExclude", BooleanParam);
  const [updateModeParams] = useQueryParam("updateMode", ArrayParam);
  const [notUsingSmartPolicyParam] = useQueryParam("notUsingSmartPolicy", BooleanParam);
  const [smartPolicyParam] = useQueryParam("smartPolicy", ArrayParam);

  const [isSpotFriendlyParam] = useQueryParam("spotFriendly", BooleanParam);
  const [isSpotUnfriendlyParam] = useQueryParam("spotUnfriendly", BooleanParam);

  const [cappingStatuses] = useQueryParam("cappingStatuses", ArrayParam);
  const [schedulerBlockers] = useQueryParam("schedulerBlockers", ArrayParam);
  const [schedulerBlockersExclude] = useQueryParam("isSchedulerBlockersExclude", NumberParam);

  useEffect(() => {
    const cp: FilterParamObject = JSON.parse(JSON.stringify(filterQueryParams)) as FilterParamObject;

    let unevictableTypesValue: string | undefined = undefined;

    switch (true) {
      case unevictableTypes?.includes("unevictable") && unevictableTypes?.includes("notHealthy"):
        unevictableTypesValue = "unevictableOrNotHealthy";
        break;
      case unevictableTypes?.includes("unevictable"):
        unevictableTypesValue = "unevictable";
        break;
      case unevictableTypes?.includes("notHealthy"):
        unevictableTypesValue = "notHealthy";
        break;
      case unevictableTypes?.includes("LocalStorageRequested"):
        unevictableTypesValue = "LocalStorageRequested";
        break;
    }

    cp[FilterOptions.UnevictableTypes] = unevictableTypesValue;

    cp[FilterOptions.Policy] = policiesParams ? policiesParams.map((value) => String(value)) : [];
    cp[FilterOptions.Type] = typeParams ? typeParams.map((value) => String(value)) : [];
    cp[FilterOptions.Annotation] = annotationParams ? annotationParams.map((value) => String(value)) : [];
    cp[FilterOptions.annotationLogicalOperator] = annotationLogicalOperator ? annotationLogicalOperator : undefined;
    cp[FilterOptions.Label] = labelParams ? labelParams.map((value) => String(value)) : [];
    cp[FilterOptions.PriorityClass] = priorityClassParams ? priorityClassParams.map((value) => String(value)) : [];
    cp[FilterOptions.OptimizationGaps] = optimizationGapsParams
      ? optimizationGapsParams.map((value) => String(value))
      : [];
    cp[FilterOptions.HpaOptimizationType] = hpaOptimiztionTypeParams
      ? hpaOptimiztionTypeParams.map((value) => String(value))
      : [];
    cp[FilterOptions.Namespace] = namespaceParams ? namespaceParams.map((value) => String(value)) : [];

    cp[FilterOptions.NamespaceAnnotation] = namespaceAnnotations
      ? namespaceAnnotations.map((value) => String(value))
      : [];
    cp[FilterOptions.IsNamespaceAnnotationExclude] = namespaceAnnotationsExclude
      ? !!namespaceAnnotationsExclude
      : undefined;
    cp[FilterOptions.NamespaceAnnotationLogicalOperator] = logicalNamespaceAnnotations
      ? logicalNamespaceAnnotations
      : undefined;
    cp[FilterOptions.SchedulerBlockersLogicalOperator] = logicalSchedulerBlockers
      ? logicalSchedulerBlockers
      : undefined;

    cp[FilterOptions.labelsLogicalOperator] = labelLogicalOperator ? labelLogicalOperator : undefined;
    cp[FilterOptions.hpaOptimizationTypeLogicalOperator] = hpaOptimiztionTypeLogicalOperator
      ? hpaOptimiztionTypeLogicalOperator
      : undefined;
    cp[FilterOptions.Clusters] = clustersParams ? clustersParams.map((value) => String(value)) : [];
    cp[FilterOptions.UpdateMode] = updateModeParams ? updateModeParams.map((value) => String(value)) : [];

    cp[FilterOptions.Tags] = tagsParams ? tagsParams.map((value) => String(value)) : [];
    cp[FilterOptions.IsTagExclude] = isTagsExclude ? isTagsExclude : undefined;

    cp[FilterOptions.OverProvisioned] = overProvisionedParam ? !!overProvisionedParam : undefined;
    cp[FilterOptions.UnderProvisioned] = underProvisionedParam ? !!underProvisionedParam : undefined;
    cp[FilterOptions.Automated] = automatedParam ? !!automatedParam : undefined;
    cp[FilterOptions.UnAutomated] = unAutomatedParam ? !!unAutomatedParam : undefined;
    cp[FilterOptions.HasMinReplicasSavings] = hasMinReplicasSavingsParam ? !!hasMinReplicasSavingsParam : undefined;
    cp[FilterOptions.HasHpaAutomatedPolicy] = hasHpaAutomatedPolicyParam ? !!hasHpaAutomatedPolicyParam : undefined;
    cp[FilterOptions.HasHpaUnAutomatedPolicy] = hasHpaUnAutomatedPolicyParam
      ? !!hasHpaUnAutomatedPolicyParam
      : undefined;
    cp[FilterOptions.MisconfiguredHpa] = misconfiguredHpaParam ? !!misconfiguredHpaParam : undefined;
    cp[FilterOptions.HasHpa] = hasHpaParam ? !!hasHpaParam : undefined;
    cp[FilterOptions.HasGPU] = hasGPUParam ? !!hasGPUParam : undefined;
    cp[FilterOptions.SavingsWithoutMinDiff] = savingsWithoutMinDiff ? !!savingsWithoutMinDiff : undefined;

    cp[FilterOptions.UnEvictable] = unEvictableParam ? !!unEvictableParam : undefined;
    cp[FilterOptions.Savings] = savingsParam ? (savingsParam == "Positive" ? true : undefined) : undefined;

    cp[FilterOptions.OOM] = oomParam ? !!oomParam : undefined;
    cp[FilterOptions.PendingConsumers] = pendingConsumerParam ? !!pendingConsumerParam : undefined;
    cp[FilterOptions.wastedResources] = wastedResourcesParam ? wastedResourcesParam.map((value) => String(value)) : [];
    cp[FilterOptions.isWastedResourcesExclude] = isWastedResourcesExcludeParam
      ? !!isWastedResourcesExcludeParam
      : undefined;
    cp[FilterOptions.logicalWastedResources] = logicalWastedResources ? logicalWastedResources : undefined;

    cp[FilterOptions.IsPolicyExclude] = policiesParamsExclude ? !!policiesParamsExclude : undefined;
    cp[FilterOptions.IsTypeExclude] = typesParamsExclude ? !!typesParamsExclude : undefined;
    cp[FilterOptions.IsAnnotationExclude] = annotationParamsExclude ? !!annotationParamsExclude : undefined;
    cp[FilterOptions.IsLabelExclude] = labelsParamsExclude ? !!labelsParamsExclude : undefined;
    cp[FilterOptions.IsPriorityClassExclude] = priorityClassParamsExclude ? !!priorityClassParamsExclude : undefined;
    cp[FilterOptions.IsOptimizationGapsExclude] = optimizationGapsParamsExclude
      ? !!optimizationGapsParamsExclude
      : undefined;
    cp[FilterOptions.IsHpaPredictableExclude] = hpaPredictableParamsExclude ? !!hpaPredictableParamsExclude : undefined;
    cp[FilterOptions.IsNamespceExclude] = namespacesParamsExclude ? !!namespacesParamsExclude : undefined;
    cp[FilterOptions.IsClustersExclude] = clustersParamsExclude ? !!clustersParamsExclude : undefined;
    cp[FilterOptions.IsUpdateModeExclude] = isUpdateModeParams ? isUpdateModeParams : undefined;

    cp[FilterOptions.OverProvisionedCpu] = cpuOverProvisionedParam ? !!cpuOverProvisionedParam : undefined;
    cp[FilterOptions.OverProvisionedMemory] = memoryOverProvisionedParam ? !!memoryOverProvisionedParam : undefined;
    cp[FilterOptions.UnderProvisionedCpu] = cpuUnderProvisionedParam ? !!cpuUnderProvisionedParam : undefined;
    cp[FilterOptions.UnderProvisionedMemory] = memoryUnderProvisionedParam ? !!memoryUnderProvisionedParam : undefined;

    cp[FilterOptions.OverProvisioned2] = overProvisionedParam2 ? overProvisionedParam2 : undefined;
    cp[FilterOptions.UnderProvisioned2] = underProvisionedParam2 ? underProvisionedParam2 : undefined;

    cp[FilterOptions.overProvisionedWRTOrigin] = overProvisionedWRTOrigin ? overProvisionedWRTOrigin : undefined;
    cp[FilterOptions.underProvisionedWRTOrigin] = underProvisionedWRTOrigin ? underProvisionedWRTOrigin : undefined;

    cp[FilterOptions.WorkloadErrors] = workloadErrorsParam ? workloadErrorsParam.map((value) => String(value)) : [];
    cp[FilterOptions.IsWorkloadErrorsExclude] = isWorkloadErrorsExcludeParam
      ? !!isWorkloadErrorsExcludeParam
      : undefined;
    cp[FilterOptions.workloadErrorsLogicalOperator] = workloadErrorsLogicalOperator
      ? workloadErrorsLogicalOperator
      : undefined;
    cp[FilterOptions.NotUsingSmartPolicy] = notUsingSmartPolicyParam ? !!notUsingSmartPolicyParam : undefined;
    cp[FilterOptions.SmartPolicy] = smartPolicyParam ? smartPolicyParam.map((value) => String(value)) : [];
    cp[FilterOptions.IsSpotFriendly] = isSpotFriendlyParam ? !!isSpotFriendlyParam : undefined;
    cp[FilterOptions.IsSpotUnfriendly] = isSpotUnfriendlyParam ? !!isSpotUnfriendlyParam : undefined;
    cp[FilterOptions.CappingStatuses] = cappingStatuses ? cappingStatuses.map((value) => String(value)) : [];
    cp[FilterOptions.SchedulerBlockers] = schedulerBlockers ? schedulerBlockers.map((value) => String(value)) : [];
    cp[FilterOptions.isSchedulerBlockersExclude] = schedulerBlockersExclude ? !!schedulerBlockersExclude : undefined;

    setFilterQueryParams(cp);
  }, [
    clustersParams,
    namespaceParams,
    labelLogicalOperator,
    typeParams,
    annotationParams,
    annotationLogicalOperator,
    labelParams,
    policiesParams,
    overProvisionedParam,
    underProvisionedParam,
    priorityClassParams,
    optimizationGapsParams,
    hpaOptimiztionTypeParams,
    automatedParam,
    unAutomatedParam,
    hasMinReplicasSavingsParam,
    hasHpaAutomatedPolicyParam,
    hasHpaUnAutomatedPolicyParam,
    misconfiguredHpaParam,
    hasHpaParam,
    unEvictableParam,
    unevictableTypes,
    savingsParam,
    oomParam,
    pendingConsumerParam,
    wastedResourcesParam,
    isWastedResourcesExcludeParam,
    logicalWastedResources,
    policiesParamsExclude,
    typesParamsExclude,
    annotationParamsExclude,
    labelsParamsExclude,
    priorityClassParamsExclude,
    optimizationGapsParamsExclude,
    hpaPredictableParamsExclude,
    namespacesParamsExclude,
    clustersParamsExclude,
    tagsParams,
    isTagsExclude,
    cpuOverProvisionedParam,
    cpuUnderProvisionedParam,
    memoryOverProvisionedParam,
    memoryUnderProvisionedParam,
    overProvisionedParam2,
    underProvisionedParam2,
    overProvisionedWRTOrigin,
    underProvisionedWRTOrigin,
    workloadErrorsParam,
    isWorkloadErrorsExcludeParam,
    workloadErrorsLogicalOperator,
    hpaOptimiztionTypeLogicalOperator,
    updateModeParams,
    isUpdateModeParams,
    notUsingSmartPolicyParam,
    smartPolicyParam,
    namespaceAnnotations,
    logicalNamespaceAnnotations,
    namespaceAnnotationsExclude,
    isSpotFriendlyParam,
    isSpotUnfriendlyParam,
    hasGPUParam,
    cappingStatuses,
    schedulerBlockers,
    schedulerBlockersExclude,
    logicalSchedulerBlockers,
    savingsWithoutMinDiff,
  ]);
  return filterQueryParams;
};

export enum NodeFilterOptions {
  NodeGroup = "nodeGroups",
  Provisioner = "provisioners",
  AvailabilityZone = "availabilityZones",
  ScaleDownReason = "scaleDownReasons",
  Labels = "labels",
}

const convertLabelFilters = (labelFilters: string[]) => {
  const out: { key: string; value: string }[] = [];
  for (const label of labelFilters) {
    const split = label.split("=");
    if (split.length != 2) {
      continue;
    }
    const [key, value] = split;
    out.push({ key: key, value: value });
  }
  console.log("qqq", out);
  return out;
};

export const useNodeFilterQueryParams = () => {
  const [filterQueryParams, setFilterQueryParams] = useState<GetNodeGroupInfoInput>({});

  const [selectedNodeGroups] = useQueryParam(NodePoolFilterQueryParams.NodeGroups, ArrayParam);
  // TODO: nimrod - add support later
  const [selectedNodePools] = useQueryParam(NodePoolFilterQueryParams.NodePools, ArrayParam);
  const [selectedProvisioners] = useQueryParam(NodePoolFilterQueryParams.Provisioners, ArrayParam);
  const [selectedAvailabilityZones] = useQueryParam(NodePoolFilterQueryParams.AvailabilityZones, ArrayParam);
  const [selectedScaleDownReasons] = useQueryParam(NodePoolFilterQueryParams.ScaleDownReasons, ArrayParam);
  const [selectedLabels] = useQueryParam(NodePoolFilterQueryParams.NodeLabels, ArrayParam);

  useEffect(() => {
    const cp: GetNodeGroupInfoInput = {};
    cp.nodeGroups = selectedNodeGroups ? selectedNodeGroups.map((value) => String(value)) : [];
    cp.provisioners = selectedProvisioners ? selectedProvisioners.map((value) => String(value)) : [];
    cp.availabilityZones = selectedAvailabilityZones ? selectedAvailabilityZones.map((value) => String(value)) : [];
    cp.scaleDownReasons = (
      selectedScaleDownReasons ? selectedScaleDownReasons.map((value) => String(value)) : []
    ) as ScaleDownReasons[];
    cp.labels = selectedLabels ? convertLabelFilters(selectedLabels.map((value) => String(value))) : [];
    cp.nodePools = selectedNodePools ? selectedNodePools.map((value) => String(value)) : [];
    setFilterQueryParams(cp);
  }, [
    selectedAvailabilityZones,
    selectedNodeGroups,
    selectedNodePools,
    selectedProvisioners,
    selectedScaleDownReasons,
    selectedLabels,
  ]);

  return filterQueryParams;
};

export default useFilterQueryParams;
