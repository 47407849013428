import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { GetNotEvictable, GetNotEvictableResponse } from "../../../../api/fetcher";
import { MAIN_WRAPPER_CLASS_NAME } from "../../../ComposeChart/utils";
import CustomLegend from "../../../CustomLegend";
import UnEvictableBarChart from "./UnEvictableBarChart";
import { CHART_COLORS, ChartDataEntry, ElementNames } from "./utils";

const { queryFn, queryKey } = GetNotEvictable();

interface Props {
  foundClusterAutoscaler?: boolean;
}

const UnEvictableBarChartContainer = ({ foundClusterAutoscaler = true }: Props) => {
  const [chartData, setChartData] = useState<ChartDataEntry[]>([]);
  const [selectedChartNodes, setSelectedChartNodes] = useState<string[]>(Object.keys(CHART_COLORS));

  const { data, isLoading, isError, error } = useQuery<GetNotEvictableResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  useEffect(() => {
    if (data) {
      const chartData = Object.entries({
        ...data?.nodesNotEvictableData?.nodesData,
      }).map(([key, value]) => {
        return {
          name: key,
          ...value,
        };
      });

      const dataWithSumOfAllValues = chartData.map((entity) => {
        const sumOfAllValues = selectedChartNodes.reduce((acc, key) => {
          return acc + (entity[key as keyof typeof ElementNames] || 0);
        }, 0);

        return {
          ...entity,
          sumOfAllValues,
        };
      });

      const sortedData = dataWithSumOfAllValues.sort((a, b) => Number(b.sumOfAllValues) - Number(a.sumOfAllValues));

      setChartData(sortedData);
    }
  }, [data, selectedChartNodes]);

  if (isError) {
    console.log("Error fetching not evictable pods at UnEvictableBarChartContainer:", error);
    return (
      <Typography variant="body2" className="py-8 pt-0 text-main-red">
        Error fetching not evictable pods
      </Typography>
    );
  }

  if (isLoading) {
    return (
      <div className="p-8 pb-16 flex justify-center w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={clsx(MAIN_WRAPPER_CLASS_NAME, "flex flex-col gap-3 pt-8 pb-4 w-full justify-center items-center")}>
      <Typography variant="body2">Unevictable pods by node</Typography>
      <div className="h-[250px] w-full">
        <UnEvictableBarChart
          data={chartData}
          selectedChartNodes={selectedChartNodes}
          foundClusterAutoscaler={foundClusterAutoscaler}
        />
      </div>
      <div>
        <CustomLegend<string>
          selectedChartComponents={selectedChartNodes}
          setSelectedChartComponents={setSelectedChartNodes}
          componentStyle={Object.entries(CHART_COLORS).reduce((acc, [key, value]) => {
            acc[key] = { color: value, borderColor: value };
            return acc;
          }, {} as Record<string, { color: string; borderColor: string }>)}
          ChartComponents={Object.keys(CHART_COLORS).reduce((acc, key) => {
            if (!foundClusterAutoscaler && (key === "kubeSystem" || key === "localStorage")) {
              return acc;
            }
            acc[key] = key;
            return acc;
          }, {} as Record<string, string>)}
          className="-mt-1"
          renderNameFunction={(key) => ElementNames[key as keyof typeof ElementNames]}
        />
      </div>
    </div>
  );
};

export default UnEvictableBarChartContainer;
