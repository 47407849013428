import { SCALEOPS_COLORS } from "../../../../colors";

export const ELEMENTS_ORDER = ["notEvictable", "notHealthy", "ownerless", "kubeSystem", "localStorage", "totalOptimize"];

export enum ElementNames {
  notEvictable = "unevictable",
  notHealthy = "not ready",
  ownerless = "without owner",
  kubeSystem = "kube-system",
  localStorage = "local storage",
  totalOptimize = "optimized bin-packed",
}

export const CHART_COLORS = {
  totalOptimize: SCALEOPS_COLORS.greenPalette.totalOptimize,
  notEvictable: SCALEOPS_COLORS.greenPalette.notEvictable,
  notHealthy: SCALEOPS_COLORS.greenPalette.notHealthy,
  ownerless: SCALEOPS_COLORS.greenPalette.ownerless,
  kubeSystem: SCALEOPS_COLORS.greenPalette.kubeSystem,
  localStorage: SCALEOPS_COLORS.greenPalette.localStorage,
};

export type ChartDataEntry = {
  notEvictable?: number;
  notHealthy?: number;
  ownerless?: number;
  kubeSystem?: number;
  localStorage?: number;
  totalOptimize?: number;
  name: string;
};

export const getTestData = () =>
  Array.from({ length: 100 }, (_, i) => ({
    name: `example-node-${i}`,
    notEvictable: Math.floor(Math.random() * 1000000),
    notHealthy: Math.floor(Math.random() * 1000000),
    ownerless: Math.floor(Math.random() * 1000000),
    kubeSystem: Math.floor(Math.random() * 1000000),
    totalOptimize: Math.floor(Math.random() * 1000000),
  }));
